@import "components/mixin";
@import "components/other";
@import "components/fonts";
@import "fonts/style.scss";
//--help class----
strong {
  color: get-color(primary);
}
.animate{
  -webkit-transition: 0.5s ease-in-out;
     -moz-transition: 0.5s ease-in-out;
      -ms-transition: 0.5s ease-in-out;
       -o-transition: 0.5s ease-in-out;
          transition: 0.5s ease-in-out;
}
.object{
  outline: none;
  width: 100%;
  height: 100vh;
}
.bg-cover{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.height-100pr{
  height:100%;
}

.margin-y{
  position: relative;
  padding-top: rem-calc(25);
  padding-bottom: rem-calc(25);
}
.position-relative{
  position: relative;
}
.position-absolute{
  position: absolute;
  bottom: 0;
}
.grid-container.full{
  width: rem-calc(1920);
}
h2.title-section,h3.title-section {
  font-weight: 700;
  color: get-color(primary);
}
h2.title-section{
  padding-bottom: rem-calc(20);
}

.effect-h{
  @include grayscale(50);
  transition-delay: 0.2s;
  transition-duration: 0.2s;
  &:hover{
    @include grayscale(0);
    transition-delay: 0.2s;
    transition-duration: 0.2s;
  }
}
.gradient-bg{
  background: -moz-linear-gradient(left, rgba(255,255,255,1) 17%, rgba(255,255,255,0.82) 71%, rgba(255,255,255,0.82) 79%, rgba(255,255,255,0.82) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255,255,255,1) 17%,rgba(255,255,255,0.82) 71%,rgba(255,255,255,0.82) 79%,rgba(255,255,255,0.82) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255,255,255,1) 17%,rgba(255,255,255,0.82) 71%,rgba(255,255,255,0.82) 79%,rgba(255,255,255,0.82) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d1ffffff',GradientType=1 ); /* IE6-9 */
}
.bg-shadow{
  -webkit-box-shadow: 0px 4px 51px -9px rgba(0,0,0,0.65);
     -moz-box-shadow: 0px 4px 51px -9px rgba(0,0,0,0.65);
          box-shadow: 0px 4px 51px -9px rgba(0,0,0,0.65);
}
.img{
  -webkit-box-shadow: rem-calc(20 20 185 -30) rgba(0,0,0,0.6);
     -moz-box-shadow: rem-calc(20 20 185 -30) rgba(0,0,0,0.6);
          box-shadow: rem-calc(20 20 185 -30) rgba(0,0,0,0.6);
            
}
.img-icon{
  box-shadow: 11px 9px 24px -4px rgba(0,0,0,0.2);
  //width: rem-calc(400);
  height: rem-calc(400);
  border-radius: 1%;
  transition-property:width, height, border;
  transition-duration:0.2s;
  transition-delay:0.2s, 0.2s, 0.2s;
  text-decoration:none;
  z-index: 99;
}
.img-icon-blog{
 // box-shadow: 11px 9px 24px -4px rgba(0,0,0,0.2);
  width: rem-calc(400);
  height: rem-calc(300);
  border-radius: 1%;
  transition-property:width, height, border;
  transition-duration:0.2s;
  transition-delay:0.2s, 0.2s, 0.2s;
  text-decoration:none;
}
//--page-style
.logo{
 max-width: rem-calc(350);
}
img{
  border-radius: rem-calc(3);  
}
//--section-nav
.headerline{
  padding-top: rem-calc(20);
  padding-bottom: rem-calc(20);
}
//--nav------------------------

.menu{
  position: absolute;
  top:0;
  font-size: rem-calc(14);
  font-weight: 700;
  text-transform: uppercase;
  a{
    text-align: center;
    margin: rem-calc(8 8);
    position: relative;
    overflow:hidden;
    color: $black;
    &:after {
      position: absolute;
      transition: 0.2s;
      content: '';
      width: 0;
      left: 50%;
      bottom: 0;
      height: rem-calc(3);
      background: get-color(primary);
    }
    &:hover {
     cursor: pointer;
    &:after {
     width: 100%;
     left: 0;
    }
   }
  }
}
.hamburger{
outline: none;
@include hamburger(get-color(secondary),get-color(secondary),26px,20px,3px,3);
}
.lead{
  position: absolute;
  z-index: 60;
  height: rem-calc(300);
  top: rem-calc(300);
  //right: 15%;
  color: $white;
  h2{
    font-size: rem-calc(110);
    font-weight: 800;
  }
  @include breakpoint(medium down){
    top: rem-calc(75);
    text-align: center;
    h2{
      font-size: rem-calc(56);
    }
  }

}
///--Slider
.heroSlider{
  height: 80vh;
  width: 100%;
  color: $white;
  .item{
    height: 80vh;
    width: 100%;
    @extend .bg-cover;
    &::before{
      position:absolute;
      content: '';
      height: 100%;
      width: 100%;
      background:rgba($black,0.5);
    /*  background: -moz-linear-gradient(left, rgba(33,50,101,0.9) 10%, rgba(33,50,101,0.81) 32%, rgba(33,50,101,0.68) 49%, rgba(33,50,101,0.36) 73%, rgba(33,50,101,0.01) 99%, rgba(255,255,255,0) 100%);
      background: -webkit-linear-gradient(left, rgba(33,50,101,0.9) 10%,rgba(33,50,101,0.81) 32%,rgba(33,50,101,0.68) 49%,rgba(33,50,101,0.36) 73%,rgba(33,50,101,0.01) 99%,rgba(255,255,255,0) 100%);
      background: linear-gradient(to right, rgba(33,50,101,0.9) 10%,rgba(33,50,101,0.81) 32%,rgba(33,50,101,0.68) 49%,rgba(33,50,101,0.36) 73%,rgba(33,50,101,0.01) 99%,rgba(255,255,255,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6213265', endColorstr='#00ffffff',GradientType=1 );*/
    }
  .item-caption{
    color: $white;
    -webkit-animation-delay: 1.5s;
       -moz-animation-delay: 1.5s;
            animation-delay: 1.5s;
    h2{
      z-index: 99;
      color: $white;
      font-size: rem-calc(86);
      font-weight: 800;
      margin-top: rem-calc(100);
      @include breakpoint(medium down){
        font-size: rem-calc(36);
        margin-top: rem-calc(75);
      }
    }
  }
}
  .owl-dots{
   position: absolute;
   left: rem-calc(20);
   bottom: rem-calc(10);
   .owl-dot{
     outline: none;
   span{
     background: $white !important;
     width: rem-calc(20) !important;
     height: rem-calc(20) !important;
     border-radius: 0 !important;
    }
  }
    .active{
      span{
        background: $primary-color !important;
      }
    }
  }
  .owl-nav{
    position: relative;
    //top: rem-calc(-85);
    bottom: rem-calc(80);
    height: rem-calc(82);
    margin-top: 0 !important;
    cursor: pointer;
    .icon{
      color: $white;
      font-size: rem-calc(45);
    }
    .owl-prev{
      span{
        position: absolute;
        left: 50%;
        width: rem-calc(40);
        height: rem-calc(45);
        background-color: rgba(get-color(primary), 0.9);
      }
    }
      .owl-next{
        @extend .owl-prev ;
        span{
        left: 55%;
        }
      }
    }
  }
.slide_page{
  .owl-nav{
    position: relative;
    //top: rem-calc(-85);
    bottom: rem-calc(0);
    height: rem-calc(82);
    margin-top: 0 !important;
    cursor: pointer;
    .icon{
      color: $black;
      font-size: rem-calc(35);
    }
    .owl-prev{
      span{
        position: absolute;
        right: 10%;
        width: rem-calc(40);
        height: rem-calc(45);
        //background-color: rgba(get-color(primary), 0.9);
      }
    }
      .owl-next{
        @extend .owl-prev ;
        span{
        right: 2%;
        }
      }
    }

}  
.page{
  .page_img{
    position: relative;
    width: 100%;
    height: rem-calc(322);
  }
}


.slide{
   .item {
     .caption{
       padding-top: rem-calc(80);
       padding-left: rem-calc(50);
       padding-right: rem-calc(50);
     }
   }
   .owl-dots{
    //position: absolute;
    //left: rem-calc(30);
    //bottom: rem-calc(10);
    .owl-dot{
      outline: none;
    span{
      background: $light-gray !important;
      width: rem-calc(8) !important;
      height: rem-calc(8) !important;
      border-radius: 0 !important;
     }
   }
     .active{
       span{
         background: $primary-color !important;

       }
     }
   }
 }
 //--info-- OFF

 .info{
   position: relative;
   //margin-top: rem-calc(200);
   margin-bottom: rem-calc(200);
   .bg-share{
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url(../../assets/img/4284.JPG);
    @extend .bg-cover;
    z-index: -1;
    &::before{
      position:absolute;
      content: '';
      height: 100%;
      width: 100%;
      background-color:rgba(#ff6633, 0.75);
    }
   }
   h2{
     color: $white;
   }
   .inner{
     padding-top: rem-calc(100);
     padding-bottom: rem-calc(50);
     a{
      color: $white;
    }
    .icon{
      color: $white;
      font-size: rem-calc(32);
      padding-left: rem-calc(20);
      margin-right: rem-calc(10);
      vertical-align: middle;
    }
    span{
      font-size: rem-calc(24);
    }
   }
  .share{
    position: relative;
    color: rgba($white,0.9);
      .cell{
      padding: rem-calc(30);

      &:hover{
        background-color:rgba(#ff6633, 0.90);
       }
    }
  }
}

//--box
.bg-box{
  background-color: get-color(secondary);
  @extend .img ;
}
.info-box{
  //padding-top: rem-calc(0);
  padding-bottom: rem-calc(0);
.box{
  //z-index: 99;
  width: rem-calc(200);
  height: rem-calc(200);


  &:nth-child(1){
    -webkit-box-shadow: rem-calc(30 30 57 -30) rgba(0,0,0,0.8);
       -moz-box-shadow: rem-calc(30 30 57 -30) rgba(0,0,0,0.8);
            box-shadow: rem-calc(30 30 57 -30) rgba(0,0,0,0.8);

  }
  &:nth-child(4){
    -webkit-box-shadow: rem-calc(-30 -30 57 -30) rgba(0,0,0,0.8);
       -moz-box-shadow: rem-calc(-30 -30 57 -30) rgba(0,0,0,0.8);
            box-shadow: rem-calc(-30 -30 57 -30) rgba(0,0,0,0.8);

   }
.inner{
  p{
    padding: rem-calc(30);
    color: $dark-gray;
  }
  h3{
    margin-top: rem-calc(50);
    font-size: rem-calc(18);
    color: $white;
  }
  .icon{
    position: relative;
    font-size: rem-calc(75);
    top: rem-calc(42);
    color: $white;
   }
  }
 }
}
.box-img{
  position: relative;
  @extend .bg-cover;
  height: rem-calc(850);
  background-image: url(../../assets/img/3456-3.JPG);
  &::before{
    position:absolute;
    content: '';
    height: 100%;
    width: 50%;
    background-color:rgba(get-color(secondary), 0.5);
  }
}

//--section-about
.about{
  position: relative;
  padding-top: rem-calc(75);
  padding-bottom: rem-calc(250);
  //background-color: lighten($black, 93);
  .box{
    //position: relative;
    background: lighten(black, 10);
    //left: rem-calc(100);
    //padding-top: rem-calc(0);
    //padding-bottom: rem-calc(0);
    //padding-right: rem-calc(100);
    //z-index: -1;
  }
  .inner-text{
     margin: rem-calc(20 30 20 200);
  }
  h2{
    margin-top:rem-calc(15);
  }
}
.btn-about{
  padding-top: rem-calc(50);
  .inner-text{
    padding-bottom: rem-calc(20);
 }
 img{
   padding: rem-calc(20 20);
 }
 .shift{
    position: absolute;
    width: 50%;
    height: 48%;
    left: 0;
    top: 0;
    z-index: -1;
    background-color: darken(get-color(secondary), 3) ;
   &::before{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 100% + 8%;
    z-index: -1;
    background-color: darken(get-color(primary), 3) ;
    }
   }
 }

//---test about us-----
$image-hover-wrapper-transition: all 1ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
$image-hover-wrapper-transition-delay: 0.15s;
$image-hover-wrapper-banner-width: 65%;
$image-hover-wrapper-banner-topspace: 15%;
$image-hover-wrapper-banner-bg: rgba($primary-color ,0.8);
$image-hover-wrapper-icon-size: 24px;

.image-hover-wrapper {
  position: relative;

  a {
     display: inline-block;
     letter-spacing: normal;
     line-height: initial;
     margin: 0;
     padding: 0;
     position: relative;

    img {
      float: center;
      //overflow: hidden;
      width: rem-calc(420);
      height: rem-calc(230);
      //display: block;
    }

    .image-hover-wrapper-reveal {
      background-color: rgba(6, 6, 8, 0.2);
      color: $white;
      display: inline;
      height: 100%;
      margin-left: -100%;
      opacity: 0;
      position: absolute;
      width: 100%;
      z-index: 80;
      transition: $image-hover-wrapper-transition;
      transition-delay: $image-hover-wrapper-transition-delay;

      &:hover {
        opacity: 1;
        transition: $image-hover-wrapper-transition;
      }

      &:hover p {
        opacity: 1;
        transform: translateY(-100%);
        transition: $image-hover-wrapper-transition;
        transition-delay: $image-hover-wrapper-transition-delay;
      }

      p {
        bottom: 0;
        display: block;
        opacity: 0;
        position: absolute;
        //text-align: center;
        width: 100%;
        transition: $image-hover-wrapper-transition;
      }
    }
  }

  .image-hover-wrapper-banner {
    background-color: $image-hover-wrapper-banner-bg;
    color: $white;
    font-weight: 600;
    left: rem-calc(15);
    padding: rem-calc(4);
    position: absolute;
    top: $image-hover-wrapper-banner-topspace;
    width: $image-hover-wrapper-banner-width;
    z-index: 81;
  }
}


//------------
.about-nav{
  padding-top: rem-calc(100);
  padding-bottom: rem-calc(100);
  cursor: pointer;
  font-size: rem-calc(28);
  a{
    color: darken( $dark-gray, 20%);
    }
  .icon{
    vertical-align: middle;
    font-size: rem-calc(38);
  }
}
img {
  display: block;
  max-width: 100%;
}
//--quote


.block-quote {
  font-size: rem-calc(14);
  margin-left: 25px;
  padding: 10px 0 10px 30px;
  position: relative;
  border-left: 14px solid get-color(primary);

  @include breakpoint(small only){
      margin-left: 0;
  }
  blockquote {
    padding: 0;
    border-left: 0 ;
  }
}

__quote {
  border-left: 0px ;
  font-weight: 700;
  margin: 0;

}

__reference {
  color: lighten($black, 50%);
  margin: 20px 0 0;
}

//--blocks__item
.blocks{
  margin: rem-calc(50 0 50 0);
 .blocks__item{
     margin: rem-calc(50 0 50 0);
  }
 }

//--section-blog
.blog{
  padding-top: rem-calc(50);
  padding-bottom: rem-calc(50);
  background-color: lighten($black, 93);
  .article{
    margin-top: rem-calc(50);
    margin-top: rem-calc(50);
    margin-bottom: rem-calc(50);
  }
  .cell{
    //background-color: lighten($black, 94);
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(20);
  }
  .date{
    font-size: rem-calc(12);
  }
  .img-center{
    margin: 0 auto;
  }

  h2{
    margin-top:rem-calc(10);
    font-size: rem-calc(30);
  }
}

.btn-about{
  padding-top: rem-calc(50);
  .inner-text{
    padding-bottom: rem-calc(20);
 }
 img{
   padding: rem-calc(20 20);
 }
 .shift{
    position: absolute;
    width: 50%;
    height: 48%;
    left: 0;
    top: 0;
    z-index: -1;
    background-color: darken(get-color(secondary), 3) ;
   &::before{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 100% + 8%;
    z-index: -1;
    background-color: darken(get-color(primary), 3) ;
    }
   }
 }
//--section-tabs
.section-tabs{
   height: auto;
   padding: rem-calc(150 0 150 0);
   background-image: url(../../assets/img/IMG_1078.JPG);
   @extend  .bg-cover;
   background-attachment: fixed;
   .box-tabs{
     position: relative;
     background-color: get-color(primary);
     padding: rem-calc(150 0);
     margin-top: rem-calc(30);
     margin-right: rem-calc(-120);
     opacity: 0.94;
     .title-section{
       color: $white;
    }
 .tabs{
   position: relative;
   top: 30%;
   .tabs-title{
     a{
     color: $white;
     opacity: 0.6;
     outline: none;
     text-transform: uppercase;
     font-size: rem-calc(18);
     font-weight: 700;
    }
  }
  .icon-active{
    vertical-align: middle;
  }
    .is-active {
      a{
        opacity: 1;
        color: $white;
        vertical-align: middle;
        text-decoration: none;
      }

      .icon-active{
        font-weight: 900;
        margin-left: rem-calc(15);
        transition-delay: 0.05s;
        transition-duration: 0.12s;
      }
    }
  }
}
  .tabs-content{
    padding-top: rem-calc(60);
    color: get-color(secondary);
    text-decoration: none;
    .tabs-panel{
      @extend .gradient-bg;
      padding: rem-calc(40);
      box-shadow: -11px 0 34px 4px rgba(0,0,0,0.4);
      ul {
       line-height: rem-calc(50);
       font-size: rem-calc(18);
       li{
         list-style: none;
         &:before {
          content: $icon-stop2;
          font-family: 'fonts' !important;
          color: get-color(primary);
          margin-right: rem-calc(10);
          vertical-align: middle;
        }
       }
      }
     }
    }
 }
 .btn-content{
  padding-bottom: rem-calc(100);
  ul {
    line-height: rem-calc(50);
    font-size: rem-calc(18);
    li{
      list-style: none;
      &:before {
       content: $icon-stop2;
       font-family: 'fonts' !important;
       color: get-color(primary);
       margin-right: rem-calc(10);
       vertical-align: middle;
     }
    }
   }
   .title-section{
     padding-bottom: rem-calc(30);
     padding-top: rem-calc(50);
     @include breakpoint(small only){
       text-align: center;
     }
   }
 }

//contact
.animate {
  -webkit-transition: all 0.3s ease-in-out;
     -moz-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
}

.clearfix{
&:before, &:after{
  content: " ";
  display: table;
}
&:after{
  clear: both;
 }
}
.block-grid{
@extend .clearfix;
}
.section-contact{
  position: relative;
  background-color: lighten($black, 10);
  border-bottom: rem-calc(0.5) solid darken($white, 80);
  font-size: rem-calc(22);
  padding: rem-calc(20 0 20 0);
   ul{
     height: auto;
     line-height: rem-calc(50);
     font-weight: 300;
     color: rgba($white, 0.8);
     margin: 0;
     //text-align: center;
      li{
        list-style: none;
        &:hover{
          .icon{
          -webkit-transform:scale(0.95);
            -moz-transform:scale(0.95);
              -o-transform:scale(0.95);
                  transform:scale(0.95);
          color:inherit;
          }
        }
        a{
          color: inherit;
          font-weight: inherit;
        }
      }
}
  .icon{
  display:inline-block;
  margin-right: rem-calc(7);
  font-size: rem-calc(40);
  vertical-align: middle;
  color: get-color(primary);
  }
}

.btn-secion-contact{
  padding-top: rem-calc(50);
  padding-bottom: rem-calc(50);
  .inner-text{
    font-weight: 700;
    line-height: rem-calc(35);
    font-size: rem-calc(20);
    padding: rem-calc(50);
    list-style: none;
    margin: 0;
    h3{
      font-weight: inherit;
    }
  }
  .inner{
    @extend .inner-text;
    background-color: get-color(primary);
    line-height: rem-calc(60);
    color: rgba($white, 0.9);

    a{
      color: inherit;
      &:hover{
        color: inherit;
        .icon{
          -webkit-transform:scale(.9);
             -moz-transform:scale(.9);
               -o-transform:scale(.9);
                  transform:scale(.9);
          color: inherit;
        }
    }
  }
  .icon{
    display: inline-block;
    font-size: rem-calc(40);
    vertical-align: middle;
    color: get-color(secondary);
    margin-right: rem-calc(30);
    -webkit-transition: all 0.5s ease-in-out;
       -moz-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
   }
  }
}
//--team-------



// --warto zobczyc --
.other{
  background: lighten($black, 90);
  padding: rem-calc(50 0);
  }
//----
.bg-heading{
 position: absolute;
 background-image: url(../../assets/img/snowman.jpg?9090);
 @extend .bg-cover;
 width: 100%;
 height: rem-calc(300);
 z-index: -1;
 &::before{
   content: '';
   position: absolute;
   width: 100%;
   height: rem-calc(300);
   background: rgba(get-color(secondary), 0.7);
 }
}

.bg-heading-seco{
 @extend .bg-heading;
 background-image: url(../../assets/img/winter.jpg?9021);
}
.heading{
  position: relative;
  width: 100%;
  height: rem-calc(300);
  //margin-bottom: rem-calc(80);
  h2, h3, h4 {
    font-weight: 600;
    color: $white;
  }
  .icon{
    font-size: rem-calc(65);
    color: $white;

  }
  .tabs-box-1{
    //height: rem-calc(120);
    margin-top: rem-calc(20);
    padding: rem-calc(20);
    background-color: rgba(get-color(primary), 0.5);
    &:hover{
      box-shadow: 0 0 34px 10px rgba(0,0,0,0.4);
    }
  }
  .tabs-box-2{
    @extend .tabs-box-1;
    background-color: rgba(get-color(secondary), 0.5);
  }
}
.text{
  ul, ol{
    padding: rem-calc(5);
    li{
      margin-left: rem-calc(30);
    }
  }
  h4, h5{
    font-weight: 600;
    color: get-color(secondary);
  }
}
//footer

.footer{
 background-color: lighten($black, 10);
 padding:rem-calc(30 0);
 color: $white;
 .inner-text{
   font-weight: 600;
   line-height: rem-calc(35);
 }
 .text-copy{
  padding: rem-calc(50 0 0 0);
 }
 .logo-footer{
   img{
     width: rem-calc(100);
   }
  }
  .icon{
    font-size: rem-calc(30);
  }
}

//--page download
.bg-heading-primary{
  @extend .bg-heading;
}

.btn-file{
  padding-top: rem-calc(100);
  padding-bottom: rem-calc(100);
    a{
      color: $dark-gray;
    }
    .icon{
      font-size: rem-calc(38);
      color: $alert-color;
      margin-bottom: rem-calc(30);
      vertical-align: middle;
      margin-right: rem-calc(20);
    }
 }
//--map images--//

.block-image{
  width:auto;margin:0 auto
 }
.block-image__top{
 margin-bottom:15px
}
.block-image__bottom{
 margin-top:15px
}
.block-image__left,.block-image__right{
 max-width:300px;
 margin-bottom:15px
}
.block-image__left{
 float:left;
 margin-right:15px
}

@media only screen and (max-width:767px){
 .block-image__left{
   float:none;margin-right:auto
 }
}
 .block-image__right{
   float:right;margin-left:15px
 }
@media only screen and (max-width:767px)
{
 .block-image__right{
   float:none;margin-left:auto
 }
} 
.imagemap__container{
    margin:auto;max-width:800px;position:relative
   }
.imagemap__img{
    display:block;max-width:100%;width:auto
   }
.imagemap__hotspot{
    position:absolute
   }
.imagemap__hotspot--rectangle{
    background-color:rgba(0,0,0,.5);
    border:2px solid #fff
   }
.imagemap__hotspot--circle{
     background-color:rgba(0,0,0,.3);
     border:2px solid #fff;border-radius:100%;
     transform:translate(-50%,-50%)
   }
.imagemap__hotspot--point{
     background-color:#fff;
     border-radius:100%;height:30px;
     transform:translate(-50%,-50%);
     width:30px}
.imagemap__hotspot-text{
     color:#fff;
     display:block;
     font-family:sans-serif;
     font-weight:700;
     left:50%;
     position:absolute;
     top:50%;
     transform:translate(-50%,-50%)
   }
.imagemap__hotspot--point>.imagemap__hotspot-text{
     color:#000
   }
.imagemap__hotspot-content{
     background-color:#000;
     border-radius:6px;color:#fff;
     margin-top:-40px;
     padding:5px 0;
     position:absolute;
     text-align:center;
     transform:translateX(-50%);
     visibility:hidden;
     width:200px;
     z-index:1
   }
.imagemap__hotspot-content:hover,.imagemap__hotspot:hover+.imagemap__hotspot-content{
     visibility:visible
   }
.block-text{
     padding-top: 20px;
     padding-bottom: 20px;
   } 