$icomoon-font-family: "fonts" !default;
$icomoon-font-path: "../fonts" !default;

$icon-bike: "\e908";
$icon-bus: "\e90a";
$icon-compass: "\e90b";
$icon-chevron-small-right: "\e90f";
$icon-chevron-thin-down: "\e910";
$icon-chevron-thin-left: "\e90c";
$icon-chevron-thin-right: "\e90d";
$icon-chevron-thin-up: "\e911";
$icon-address: "\e90e";
$icon-location-pin: "\e900";
$icon-mail: "\e902";
$icon-phone: "\e903";
$icon-facebook-with-circle: "\e904";
$icon-google-with-circle: "\e905";
$icon-twitter-with-circle: "\e906";
$icon-youtube-with-circle: "\e907";
$icon-cloud-download: "\e901";
$icon-mouse: "\e909";
$icon-stop2: "\ea1e";
$icon-file-pdf: "\eadf";
