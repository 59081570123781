//--------medium-1024px
//section-1

@include breakpoint(medium down){

  //slider
.heroSlider{
  height: 80vh;
  .item{
    height: 80vh;
 }
}
//--nav--
.menu{
  font-size: rem-calc(18);
}
//--nav--
.top-bar{
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  background-color: rgba($white,0.94);
  z-index: 99;

}
.section-introduction{
  padding: rem-calc(80 0 80 0);
}
.img-right{
  margin-top: 0;
  margin-left: 0;
}

//section tabs
.section-tabs{
  padding: rem-calc(50 0 50 0);
  .box-tabs{
    padding: rem-calc(30 0);
    margin-right: 0;
    height: rem-calc(300);
    .tabs{
      top: 0;
   }
 }
 .tabs-content{
   padding-top: rem-calc(0);
  }
 }
.box-size{
  height: auto;
}
.section-introduction{
  .inner-text{
    padding: rem-calc( 50 0 50 0);
 }
 .bg-primary{
    width: 100% + 10%;
    left: rem-calc(-15);
 }
}
//--section-about
.about{
  //padding-top: rem-calc(10);
  padding-bottom: rem-calc(50);
  .cell{
    margin-top: rem-calc(85);
  }
  .inner{
    padding-top: rem-calc(0);
  }
}
.about-btn{
  .inner-text{
    padding-right: 0;
 }
}
//--section blog
.blog{
  //padding-top: rem-calc(10);
  padding-bottom: rem-calc(50);
  .inner{
    padding-top: rem-calc(0);
  }
}
.about-btn{
  .inner-text{
    padding-right: 0;
 }
}
//--box
.info-box{
  padding-top: rem-calc(50);
  //padding-bottom: rem-calc(0);
}
  //--info-box--
  .box-img{
    height: rem-calc(550);
    &::before{
      width: 100%;
    }
  }
//--footer--
.footer{
 .inner-text{
   padding-bottom: rem-calc(30);
 }
 .logo-footer{
   padding-bottom: rem-calc(30);

  }
}
//--info ofert--
.info{
  margin-bottom: rem-calc(0);
}

//--info
.secion-contact{
  .inner{
    font-size: rem-calc(16);
  }
  .icon{
    margin-right: rem-calc(5);
  }
}
.section-contact{
  font-size: rem-calc(18);
  text-align: center;
  span{
    display: block;
  }
}
.btn-about{
  .inner-text{
    padding: rem-calc(5 5);
  }
 }
 .about-nav{
  font-size: rem-calc(20);
  .icon{
   font-size: rem-calc(30);
  }
 }
}

//------------small-640px
@include breakpoint(small only){
//tabs
.section-tabs{
  .box-tabs{
    .tabs{
      .tabs-title > a{
        font-size: rem-calc(16);
     }
    }
   }
  }
  //--other--
.bg-other{
  padding: rem-calc(50 0);
}
.other{
  padding: rem-calc(30 0);
  }
  .section-contact{
    text-align: left;
    span{
      display: inline-block;
    }
  }
//--teasers--
.image-hover-wrapper {
      a { 
      img {
        width: rem-calc(720);
        height: rem-calc(330);
      }
    }
  }
}
