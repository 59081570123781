//cookieinfo

#cookieinfo {
    position: fixed;
    text-align: center;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba($black,0.8);
    padding: rem-calc(40 0 20 0);
    z-index: 1000;
    @include breakpoint(small only){
      left: 0;
    }

 h6 {
    color:$white;
}

 div {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    width: rem-calc(200);
    height: rem-calc(40);
    padding: rem-calc(5 0);
    font-weight: 600;
    font-size: rem-calc(18);
    color: $white;
    text-decoration: none;
    background-color: get-color(primary);
  }
}
//button------------------
.line{
  position: absolute;
  bottom: rem-calc(300);
  right: rem-calc(73);
  width: rem-calc(4);
  min-height: rem-calc(350);
  border-left: rem-calc(3) solid $white;
  z-index: 3;
}
.button-down {
    position: absolute;
    //padding-top: rem-calc(28);
    bottom: rem-calc(50);
    right: rem-calc(20);
    z-index: 30;
    text-decoration:none;
    text-align: center;
    -webkit-transition: padding-top 0.2s ease-out;
       -moz-transition: padding-top 0.2s ease-out;
            transition: padding-top 0.2s ease-out;
  p{
    font-size: rem-calc(12);
    font-weight: 800;
    color: $white;
    letter-spacing: rem-calc(2);
    text-transform:uppercase;
    margin-bottom: rem-calc(60);
    -webkit-transform: rotate(90deg);
       -moz-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .icon{
    font-size: rem-calc(24);
    margin-right: rem-calc(-2);
    color: get-color(primary);
  }
  &:hover{
//    padding-top: rem-calc(32);
    .icon {
      color: $medium-gray;
    }
  }
}
.button-up {
  display: inline-block;
  width: rem-calc(40);
  height: rem-calc(40);
  padding-top: rem-calc(9);
  right: rem-calc(30);
  text-align: center;
  border-radius: 5%;
  position: fixed;
  bottom: rem-calc(80);
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  background-color: get-color(primary);
  -webkit-transition: padding-top 0.2s ease-out;
     -moz-transition: padding-top 0.2s ease-out;
          transition: padding-top 0.2s ease-out;
  .icon{
    color: $white;
    font-size: rem-calc(24);
  }
  &:hover{
    padding-top: rem-calc(5);
  }
}
.show {
  opacity: 1;
  visibility: visible;
}
.button-line{
  position: relative;
  top: rem-calc(15);
  color: $white;
  //left: 45%;
  background: get-color(primary);
  margin: rem-calc(0);
  width: rem-calc(210);
  height: rem-calc(35);
  //border-radius: rem-calc(30);
  color: $white;
  //@extend .bg-shadow ;
   .icon{
     position: relative;
     display: inline-block;
     font-size: rem-calc(30);
     margin-top: rem-calc(-1);
     margin-left: rem-calc(-1);
     vertical-align: middle;
     -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
          -o-transition: all 0.2s ease-in-out;
             transition: all 0.2s ease-in-out;
   }
   a{
     color: $white;
   }
   &:hover{
     background-color: darken(get-color(primary),5);
     .icon{
       transform:translate(rem-calc(4),0);
       color: $white;
     }
     a{
       color: $white;
     }
   }
 }

//-------------------------
.preload
{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 10000;
    visibility: hidden;
    opacity: 0;
    width: 100vw;
    height: 100vh;
    top: 0;
    background-color: $white;
    transition: opacity 400ms ease-in-out, visibility 0s 400ms;
}

.show-preloader{
  overflow: hidden;
}


.loader {
  border: rem-calc(10) solid $white;
  border-radius: 50%;
  //border-top: 16px solid ;
  border-top: rem-calc(10) solid #ff0000;
  border-bottom: rem-calc(10) solid #ff0000;
  width: rem-calc(80);
  height: rem-calc(80);
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
//------------

