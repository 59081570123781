
//dimming
@mixin dimming($dimming) {
 -webkit-filter: brightness($dimming);
 -moz-filter: brightness($dimming);
 -o-filter: brightness($dimming);
 -ms-filter: brightness($dimming);
 filter: brightness($dimming);
}
//blur
@mixin blur($blur) {
  -webkit-filter: blur($blur);
  -moz-filter: blur($blur);
  -o-filter: blur($blur);
  -ms-filter: blur($blur);
  filter: blur($blur);
}
//shadow
@mixin shadow ($top, $right, $buttom, $left, $color-rgba){
  -webkit-box-shadow: $top $right $buttom $left $color-rgba;
  -moz-box-shadow: $top $right $buttom $left $color-rgba;
  box-shadow: $top $right $buttom $left $color-rgba;
}
//przyciemnienie
@mixin brightness($opascity){
  -webkit-filter: brightness($opascity);
  -moz-filter: brightness($opascity);
  -o-filter: brightness($opascity);
  -ms-filter: brightness($opascity);
  filter: brightness($opascity);
}
//skala szarości
@mixin grayscale($value){

  filter: grayscale($value);
}
