@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?x2bljz');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?x2bljz#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?x2bljz') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?x2bljz') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?x2bljz##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bike {
  &:before {
    content: $icon-bike; 
  }
}
.icon-bus {
  &:before {
    content: $icon-bus; 
  }
}
.icon-compass {
  &:before {
    content: $icon-compass; 
  }
}
.icon-chevron-small-right {
  &:before {
    content: $icon-chevron-small-right; 
  }
}
.icon-chevron-thin-down {
  &:before {
    content: $icon-chevron-thin-down; 
  }
}
.icon-chevron-thin-left {
  &:before {
    content: $icon-chevron-thin-left; 
  }
}
.icon-chevron-thin-right {
  &:before {
    content: $icon-chevron-thin-right; 
  }
}
.icon-chevron-thin-up {
  &:before {
    content: $icon-chevron-thin-up; 
  }
}
.icon-address {
  &:before {
    content: $icon-address; 
  }
}
.icon-location-pin {
  &:before {
    content: $icon-location-pin; 
  }
}
.icon-mail {
  &:before {
    content: $icon-mail; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-facebook-with-circle {
  &:before {
    content: $icon-facebook-with-circle; 
  }
}
.icon-google-with-circle {
  &:before {
    content: $icon-google-with-circle; 
  }
}
.icon-twitter-with-circle {
  &:before {
    content: $icon-twitter-with-circle; 
  }
}
.icon-youtube-with-circle {
  &:before {
    content: $icon-youtube-with-circle; 
  }
}
.icon-cloud-download {
  &:before {
    content: $icon-cloud-download; 
  }
}
.icon-mouse {
  &:before {
    content: $icon-mouse; 
  }
}
.icon-stop2 {
  &:before {
    content: $icon-stop2; 
  }
}
.icon-file-pdf {
  &:before {
    content: $icon-file-pdf; 
  }
}

